<template>
  <div class="d-flex justify-center align-center h-100vh">
    <div class="d-flex align-center">
      <v-text-field label="Password" class="pr-3" v-model="password" required/>
      
      <v-btn @click="check">GO</v-btn>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from "vuex";

  export default {
    data() {
      return {
        password: "",
      };
    },

    methods: {
      ...mapMutations({
        setLoggedStatus: "SET_LOGGED_IN",
      }),

      check() {
        const { password } = this;

        if (password == "CrYpToArT") {
          this.setLoggedStatus(true);
          this.$router.push("/");
        }
      },
    },
  };
</script>

<style type="text/css">
  .h-100vh {
    height: 100vh;
  }
</style>
